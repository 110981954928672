import { UseContextReturn } from '~/types/core';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';
import { useConfigStore } from "~/stores/config";

export const getProductDetailsCommand = {
  execute: async (context: UseContextReturn, searchParams: GetProductSearchParams, customQuery = { productDetails: 'productDetails' }, customHeaders = {}) => {
    const configStore = useConfigStore();
    let store_code = configStore.storeConfig.store_code;
    const { data } = await context.app.$vsf.$magento.api.productDetails(searchParams, customQuery, {...customHeaders, ...{ store: store_code === "nl" ? "nl_et" : store_code}});

    return data?.products ?? null;
  },
};
