



















import LazyHydrate from "vue-lazy-hydration";
import {
  useRoute,
  defineComponent,
  useAsync,
  useRouter,
  useContext,
  onMounted,
} from "@nuxtjs/composition-api";
import { useUiState } from "~/composables";
import AppHeader from "~/components/AppHeader.vue";
import BottomNavigation from "~/components/BottomNavigation.vue";
import IconSprite from "~/components/General/IconSprite.vue";
import LoadWhenVisible from "~/components/utils/LoadWhenVisible.vue";
import TopBar from "~/components/TopBar/TopBar.vue";
import { useCart } from "~/modules/checkout/composables/useCart";
import { useCustomerStore } from "~/modules/customer/stores/customer";
import useUserB2b from "~/modules/customer/composables/useUserB2b";

export default defineComponent({
  name: "DefaultLayout",

  components: {
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    BottomNavigation,
    IconSprite,
    TopBar,
    AppFooter: () =>
      import(/* webpackPrefetch: true */ "~/components/AppFooter.vue"),
    WishlistSidebar: () =>
      import(
        /* webpackPrefetch: true */ "~/modules/wishlist/components/WishlistSidebar.vue"
      ),
    LoginModal: () =>
      import(
        /* webpackPrefetch: true */ "~/modules/customer/components/LoginModal/LoginModal.vue"
      ),
    Notification: () =>
      import(/* webpackPrefetch: true */ "~/components/Notification.vue"),
  },

  middleware({ app, redirect }) {
    if (app.context.route.query.token) {
      app.context.$cookies.remove("vsf-customer");
      app.context.$cookies.remove("vsf-cart");
      app.context.$cookies.remove("sale-person");
      app.context.$cookies.set("vsf-customer", app.context.route.query.token);
      app.context.$cookies.set(
        "sale-person",
        app.context.route.query.salesperson_session
      );
      return redirect("/");
    }
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const { app } = useContext();
    const {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
    } = useUiState();

    const { getUserData } = useUserB2b();
    const customerStore = useCustomerStore();

    const { load: loadCart, applyCoupon, cart } = useCart();

    useAsync(async () => {
      await loadCart();
      const response = await getUserData();
      customerStore.setIsB2b(response.data?.customer?.is_b2b);
    });

    onMounted(async () => {
      if (!cart.value && route && route.value && route.value.query.coupon) {
        app.$cookies.set("coupon-code", route.value.query.coupon);
      } else if (route && route.value && route.value.query.coupon) {
        const coupon: any = route.value.query.coupon ?? "";
        await applyCoupon({ couponCode: coupon });
        router.push(app.localePath("/"));
      }
    });

    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      route,
    };
  },
  head: {
    link: [{ rel: "stylesheet", href: "/_nuxt/fonts.css" }],
  },
  mounted() {
    // Get the header
    const header = document.getElementById("myHeader");
    const mainContent = document.getElementById("layout");
    const addStickyHeader = () => {
      const scrollPosition =
        window.scrollY ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      if (scrollPosition > sticky) {
        header.classList.add("stickyHeader");
        mainContent.classList.add("stickyLayout");
      } else {
        header.classList.remove("stickyHeader");
        mainContent.classList.remove("stickyLayout");
      }
    };
    window.onscroll = function () {
      addStickyHeader();
    };

    // Get the offset position of the navbar
    const sticky = header.offsetTop;
  },
});
