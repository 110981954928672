import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _fee37f38 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _d5efbed4 = () => interopDefault(import('../pages/AboutUs.vue' /* webpackChunkName: "pages/AboutUs" */))
const _3d409776 = () => interopDefault(import('../pages/Business.vue' /* webpackChunkName: "pages/Business" */))
const _08e4a1fc = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _307b4928 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _4339ab18 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _7d127c7f = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _2dbc5a85 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _174a262c = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _7df10fdb = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _4b0e0554 = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _5542332b = () => interopDefault(import('../pages/Contact.vue' /* webpackChunkName: "pages/Contact" */))
const _63539f8e = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _7b9e7ca7 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _25a3e5ac = () => interopDefault(import('../modules/customer/pages/MyAccount/FastOrder.vue' /* webpackChunkName: "" */))
const _073ab2f7 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyDownloads.vue' /* webpackChunkName: "" */))
const _6f462c5d = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _1bfbe963 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _266019b2 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _a7392b2e = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _6a90ec32 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _47af0ab3 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _3eb535e2 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _839146f2 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _5a4a82ac = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _55ab3ebe = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _163302ba = () => interopDefault(import('../pages/ForgotPassword.vue' /* webpackChunkName: "pages/ForgotPassword" */))
const _5e7953d8 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _b8a20cb0 = () => interopDefault(import('../pages/Register.vue' /* webpackChunkName: "pages/Register" */))
const _0e03eee6 = () => interopDefault(import('../pages/Storyblok.vue' /* webpackChunkName: "pages/Storyblok" */))
const _e463e9b2 = () => interopDefault(import('../pages/thank-you.vue' /* webpackChunkName: "pages/thank-you" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de_et",
    component: _fee37f38,
    name: "home___de_et"
  }, {
    path: "/fr_et",
    component: _fee37f38,
    name: "home___fr_et"
  }, {
    path: "/nl_et",
    component: _fee37f38,
    name: "home___nl_et"
  }, {
    path: "/us_et",
    component: _fee37f38,
    name: "home___us_et"
  }, {
    path: "/de_et/AboutUs",
    component: _d5efbed4,
    name: "AboutUs___de_et"
  }, {
    path: "/de_et/Business",
    component: _3d409776,
    name: "Business___de_et"
  }, {
    path: "/de_et/cart",
    component: _08e4a1fc,
    name: "cart___de_et"
  }, {
    path: "/de_et/checkout",
    component: _307b4928,
    name: "checkout___de_et",
    children: [{
      path: "billing",
      component: _4339ab18,
      name: "billing___de_et"
    }, {
      path: "payment",
      component: _7d127c7f,
      name: "payment___de_et"
    }, {
      path: "shipping",
      component: _2dbc5a85,
      name: "shipping___de_et"
    }, {
      path: "thank-you",
      component: _174a262c,
      name: "thank-you___de_et"
    }, {
      path: "user-account",
      component: _7df10fdb,
      name: "user-account___de_et"
    }]
  }, {
    path: "/de_et/Cms",
    component: _4b0e0554,
    name: "Cms___de_et"
  }, {
    path: "/de_et/Contact",
    component: _5542332b,
    name: "Contact___de_et"
  }, {
    path: "/de_et/customer",
    component: _63539f8e,
    meta: {"titleLabel":"My Account"},
    name: "customer___de_et",
    children: [{
      path: "addresses-details",
      component: _7b9e7ca7,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___de_et"
    }, {
      path: "fast-order",
      component: _25a3e5ac,
      meta: {"titleLabel":"Fast Order"},
      name: "fast-order___de_et"
    }, {
      path: "my-downloads",
      component: _073ab2f7,
      meta: {"titleLabel":"My downloads"},
      name: "customer-my-downloads___de_et"
    }, {
      path: "my-newsletter",
      component: _6f462c5d,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___de_et"
    }, {
      path: "my-profile",
      component: _1bfbe963,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___de_et"
    }, {
      path: "my-reviews",
      component: _266019b2,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___de_et"
    }, {
      path: "my-wishlist",
      component: _a7392b2e,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___de_et"
    }, {
      path: "order-history",
      component: _6a90ec32,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___de_et"
    }, {
      path: "addresses-details/create",
      component: _47af0ab3,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___de_et"
    }, {
      path: "/de_et/reset-password",
      component: _3eb535e2,
      alias: "/customer/account/createPassword",
      name: "reset-password___de_et"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _839146f2,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___de_et"
    }, {
      path: "order-history/:orderId",
      component: _5a4a82ac,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___de_et"
    }]
  }, {
    path: "/de_et/Faq",
    component: _55ab3ebe,
    name: "Faq___de_et"
  }, {
    path: "/de_et/ForgotPassword",
    component: _163302ba,
    name: "ForgotPassword___de_et"
  }, {
    path: "/de_et/Home",
    component: _fee37f38,
    name: "Home___de_et"
  }, {
    path: "/de_et/Page",
    component: _5e7953d8,
    name: "Page___de_et"
  }, {
    path: "/de_et/Register",
    component: _b8a20cb0,
    name: "Register___de_et"
  }, {
    path: "/de_et/Storyblok",
    component: _0e03eee6,
    name: "Storyblok___de_et"
  }, {
    path: "/de_et/thank-you",
    component: _e463e9b2,
    name: "thank-you___de_et"
  }, {
    path: "/fr_et/AboutUs",
    component: _d5efbed4,
    name: "AboutUs___fr_et"
  }, {
    path: "/fr_et/Business",
    component: _3d409776,
    name: "Business___fr_et"
  }, {
    path: "/fr_et/cart",
    component: _08e4a1fc,
    name: "cart___fr_et"
  }, {
    path: "/fr_et/checkout",
    component: _307b4928,
    name: "checkout___fr_et",
    children: [{
      path: "billing",
      component: _4339ab18,
      name: "billing___fr_et"
    }, {
      path: "payment",
      component: _7d127c7f,
      name: "payment___fr_et"
    }, {
      path: "shipping",
      component: _2dbc5a85,
      name: "shipping___fr_et"
    }, {
      path: "thank-you",
      component: _174a262c,
      name: "thank-you___fr_et"
    }, {
      path: "user-account",
      component: _7df10fdb,
      name: "user-account___fr_et"
    }]
  }, {
    path: "/fr_et/Cms",
    component: _4b0e0554,
    name: "Cms___fr_et"
  }, {
    path: "/fr_et/Contact",
    component: _5542332b,
    name: "Contact___fr_et"
  }, {
    path: "/fr_et/customer",
    component: _63539f8e,
    meta: {"titleLabel":"My Account"},
    name: "customer___fr_et",
    children: [{
      path: "addresses-details",
      component: _7b9e7ca7,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___fr_et"
    }, {
      path: "fast-order",
      component: _25a3e5ac,
      meta: {"titleLabel":"Fast Order"},
      name: "fast-order___fr_et"
    }, {
      path: "my-downloads",
      component: _073ab2f7,
      meta: {"titleLabel":"My downloads"},
      name: "customer-my-downloads___fr_et"
    }, {
      path: "my-newsletter",
      component: _6f462c5d,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___fr_et"
    }, {
      path: "my-profile",
      component: _1bfbe963,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___fr_et"
    }, {
      path: "my-reviews",
      component: _266019b2,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___fr_et"
    }, {
      path: "my-wishlist",
      component: _a7392b2e,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___fr_et"
    }, {
      path: "order-history",
      component: _6a90ec32,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___fr_et"
    }, {
      path: "addresses-details/create",
      component: _47af0ab3,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___fr_et"
    }, {
      path: "/fr_et/reset-password",
      component: _3eb535e2,
      alias: "/customer/account/createPassword",
      name: "reset-password___fr_et"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _839146f2,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___fr_et"
    }, {
      path: "order-history/:orderId",
      component: _5a4a82ac,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___fr_et"
    }]
  }, {
    path: "/fr_et/Faq",
    component: _55ab3ebe,
    name: "Faq___fr_et"
  }, {
    path: "/fr_et/ForgotPassword",
    component: _163302ba,
    name: "ForgotPassword___fr_et"
  }, {
    path: "/fr_et/Home",
    component: _fee37f38,
    name: "Home___fr_et"
  }, {
    path: "/fr_et/Page",
    component: _5e7953d8,
    name: "Page___fr_et"
  }, {
    path: "/fr_et/Register",
    component: _b8a20cb0,
    name: "Register___fr_et"
  }, {
    path: "/fr_et/Storyblok",
    component: _0e03eee6,
    name: "Storyblok___fr_et"
  }, {
    path: "/fr_et/thank-you",
    component: _e463e9b2,
    name: "thank-you___fr_et"
  }, {
    path: "/nl_et/AboutUs",
    component: _d5efbed4,
    name: "AboutUs___nl_et"
  }, {
    path: "/nl_et/Business",
    component: _3d409776,
    name: "Business___nl_et"
  }, {
    path: "/nl_et/cart",
    component: _08e4a1fc,
    name: "cart___nl_et"
  }, {
    path: "/nl_et/checkout",
    component: _307b4928,
    name: "checkout___nl_et",
    children: [{
      path: "billing",
      component: _4339ab18,
      name: "billing___nl_et"
    }, {
      path: "payment",
      component: _7d127c7f,
      name: "payment___nl_et"
    }, {
      path: "shipping",
      component: _2dbc5a85,
      name: "shipping___nl_et"
    }, {
      path: "thank-you",
      component: _174a262c,
      name: "thank-you___nl_et"
    }, {
      path: "user-account",
      component: _7df10fdb,
      name: "user-account___nl_et"
    }]
  }, {
    path: "/nl_et/Cms",
    component: _4b0e0554,
    name: "Cms___nl_et"
  }, {
    path: "/nl_et/Contact",
    component: _5542332b,
    name: "Contact___nl_et"
  }, {
    path: "/nl_et/customer",
    component: _63539f8e,
    meta: {"titleLabel":"My Account"},
    name: "customer___nl_et",
    children: [{
      path: "addresses-details",
      component: _7b9e7ca7,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___nl_et"
    }, {
      path: "fast-order",
      component: _25a3e5ac,
      meta: {"titleLabel":"Fast Order"},
      name: "fast-order___nl_et"
    }, {
      path: "my-downloads",
      component: _073ab2f7,
      meta: {"titleLabel":"My downloads"},
      name: "customer-my-downloads___nl_et"
    }, {
      path: "my-newsletter",
      component: _6f462c5d,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___nl_et"
    }, {
      path: "my-profile",
      component: _1bfbe963,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___nl_et"
    }, {
      path: "my-reviews",
      component: _266019b2,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___nl_et"
    }, {
      path: "my-wishlist",
      component: _a7392b2e,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___nl_et"
    }, {
      path: "order-history",
      component: _6a90ec32,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___nl_et"
    }, {
      path: "addresses-details/create",
      component: _47af0ab3,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___nl_et"
    }, {
      path: "/nl_et/reset-password",
      component: _3eb535e2,
      alias: "/customer/account/createPassword",
      name: "reset-password___nl_et"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _839146f2,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___nl_et"
    }, {
      path: "order-history/:orderId",
      component: _5a4a82ac,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___nl_et"
    }]
  }, {
    path: "/nl_et/Faq",
    component: _55ab3ebe,
    name: "Faq___nl_et"
  }, {
    path: "/nl_et/ForgotPassword",
    component: _163302ba,
    name: "ForgotPassword___nl_et"
  }, {
    path: "/nl_et/Home",
    component: _fee37f38,
    name: "Home___nl_et"
  }, {
    path: "/nl_et/Page",
    component: _5e7953d8,
    name: "Page___nl_et"
  }, {
    path: "/nl_et/Register",
    component: _b8a20cb0,
    name: "Register___nl_et"
  }, {
    path: "/nl_et/Storyblok",
    component: _0e03eee6,
    name: "Storyblok___nl_et"
  }, {
    path: "/nl_et/thank-you",
    component: _e463e9b2,
    name: "thank-you___nl_et"
  }, {
    path: "/us_et/AboutUs",
    component: _d5efbed4,
    name: "AboutUs___us_et"
  }, {
    path: "/us_et/Business",
    component: _3d409776,
    name: "Business___us_et"
  }, {
    path: "/us_et/cart",
    component: _08e4a1fc,
    name: "cart___us_et"
  }, {
    path: "/us_et/checkout",
    component: _307b4928,
    name: "checkout___us_et",
    children: [{
      path: "billing",
      component: _4339ab18,
      name: "billing___us_et"
    }, {
      path: "payment",
      component: _7d127c7f,
      name: "payment___us_et"
    }, {
      path: "shipping",
      component: _2dbc5a85,
      name: "shipping___us_et"
    }, {
      path: "thank-you",
      component: _174a262c,
      name: "thank-you___us_et"
    }, {
      path: "user-account",
      component: _7df10fdb,
      name: "user-account___us_et"
    }]
  }, {
    path: "/us_et/Cms",
    component: _4b0e0554,
    name: "Cms___us_et"
  }, {
    path: "/us_et/Contact",
    component: _5542332b,
    name: "Contact___us_et"
  }, {
    path: "/us_et/customer",
    component: _63539f8e,
    meta: {"titleLabel":"My Account"},
    name: "customer___us_et",
    children: [{
      path: "addresses-details",
      component: _7b9e7ca7,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___us_et"
    }, {
      path: "fast-order",
      component: _25a3e5ac,
      meta: {"titleLabel":"Fast Order"},
      name: "fast-order___us_et"
    }, {
      path: "my-downloads",
      component: _073ab2f7,
      meta: {"titleLabel":"My downloads"},
      name: "customer-my-downloads___us_et"
    }, {
      path: "my-newsletter",
      component: _6f462c5d,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___us_et"
    }, {
      path: "my-profile",
      component: _1bfbe963,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___us_et"
    }, {
      path: "my-reviews",
      component: _266019b2,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___us_et"
    }, {
      path: "my-wishlist",
      component: _a7392b2e,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___us_et"
    }, {
      path: "order-history",
      component: _6a90ec32,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___us_et"
    }, {
      path: "addresses-details/create",
      component: _47af0ab3,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___us_et"
    }, {
      path: "/us_et/reset-password",
      component: _3eb535e2,
      alias: "/customer/account/createPassword",
      name: "reset-password___us_et"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _839146f2,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___us_et"
    }, {
      path: "order-history/:orderId",
      component: _5a4a82ac,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___us_et"
    }]
  }, {
    path: "/us_et/Faq",
    component: _55ab3ebe,
    name: "Faq___us_et"
  }, {
    path: "/us_et/ForgotPassword",
    component: _163302ba,
    name: "ForgotPassword___us_et"
  }, {
    path: "/us_et/Home",
    component: _fee37f38,
    name: "Home___us_et"
  }, {
    path: "/us_et/Page",
    component: _5e7953d8,
    name: "Page___us_et"
  }, {
    path: "/us_et/Register",
    component: _b8a20cb0,
    name: "Register___us_et"
  }, {
    path: "/us_et/Storyblok",
    component: _0e03eee6,
    name: "Storyblok___us_et"
  }, {
    path: "/us_et/thank-you",
    component: _e463e9b2,
    name: "thank-you___us_et"
  }, {
    path: "/de_et/:slug+",
    component: _5e7953d8,
    name: "page___de_et"
  }, {
    path: "/fr_et/:slug+",
    component: _5e7953d8,
    name: "page___fr_et"
  }, {
    path: "/nl_et/:slug+",
    component: _5e7953d8,
    name: "page___nl_et"
  }, {
    path: "/us_et/:slug+",
    component: _5e7953d8,
    name: "page___us_et"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
