import useApi from '~/composables/useApi';
import queryString from './queryString.gql'

import { useContext } from "@nuxtjs/composition-api"

const useUserB2b = () => {
  const { app } = useContext()
  const { query } = useApi();

  

  const getUserData = async () => {
    const customerCookie = app.$cookies.get("vsf-customer")
    if(!customerCookie) {
      return false
    }
    return query(queryString)
    .then((data) => {
      return data;
    })
    .catch((e) => {
      return e.response
    });
  }
  return {
    getUserData
  }
}

export default useUserB2b
