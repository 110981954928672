//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FooterColumn from "./FooterColumn.vue";
export default {
  props: ["blok"],
  components: {
    FooterColumn,
  },
};
