import { defineStore } from 'pinia';
import { AvailableStores } from '~/composables/types';
import { Currency, StoreConfig } from '~/modules/GraphQL/types';

interface ConfigState {
  storeConfig: StoreConfig,
  stores: AvailableStores
  currency: Currency,
  menColor: Boolean,
  logoCategoryUid: String,
}

export const useConfigStore = defineStore('magentoConfig', {
  state: (): ConfigState => ({
    storeConfig: {},
    stores: [],
    currency: {},
    menColor: false,
    logoCategoryUid: '',
  }),
  actions: {


    setLogoCategoryUid(payload) {
      this.logoCategoryUid = payload;
    },

  },
});
