


























































































































































































































































import { SfOverlay, SfButton } from "@storefront-ui/vue";

import {
  computed,
  ref,
  reactive,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
  useFetch,
} from "@nuxtjs/composition-api";
import { useUiHelpers, useUiState } from "~/composables";
import { useCart } from "~/modules/checkout/composables/useCart";
import { useWishlist } from "~/modules/wishlist/composables/useWishlist";
import { useUser } from "~/modules/customer/composables/useUser";
import { useWishlistStore } from "~/modules/wishlist/store/wishlistStore";
import type { CategoryTree, ProductInterface } from "~/modules/GraphQL/types";
import HeaderLogo from "~/components/HeaderLogo.vue";
import { useTopBar } from "./TopBar/useTopBar";
import { Icon } from "@iconify/vue2";
import { clickOutside } from "~/components/directives/click-outside/click-outside-directive";
import BackDrop from "./General/BackDrop.vue";
import useStoryblok from "~/composables/useStoryblok";
import { useCategoryStore } from "~/modules/catalog/category/stores/category";
const MobileCategorySidebar = () =>
  import(
    "~/modules/catalog/category/components/sidebar/MobileCategorySidebar/MobileCategorySidebar.vue"
  );

import { useConfigStore } from "~/stores/config";
import { useTraverseCategory } from "~/modules/catalog/category/helpers/useTraverseCategory";
export default defineComponent({
  components: {
    HeaderLogo,
    SfButton,
    BackDrop,
    CurrencySelector: () => import("~/components/CurrencySelector.vue"),
    StoreSwitcher: () => import("~/components/StoreSwitcher.vue"),
    SearchBar: () => import("~/components/Header/SearchBar/SearchBar.vue"),

    SearchResults: () =>
      import(
        /* webpackPrefetch: true */ "~/components/Header/SearchBar/SearchResults.vue"
      ),

    Icon,
    CartModal: () =>
      import(
        /* webpackPrefetch: true */ "~/modules/checkout/components/CartModal.vue"
      ),
    SfOverlay,
    MobileCategorySidebar,
  },
  directives: { clickOutside },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const {
      toggleCartSidebar,
      toggleWishlistSidebar,
      toggleLoginModal,
      toggleMobileMenu,
      isMobileMenuOpen,
      isCartSidebarOpen,
    } = useUiState();

    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated } = useUser();
    const { loadTotalQty: loadCartTotalQty, cart, load: loadCart } = useCart();

    const { isCategoryTreeLoaded, loadCategoryTree, categoryTreeWithColors } =
      useTraverseCategory();

    const configStore = useConfigStore();
    const { setLogoCategoryUid } = useConfigStore();
    const logoDefault = () => {
      setLogoCategoryUid(null);
      isSearchOpen.value = !isSearchOpen.value;
    };
    const logoCategoryUid = computed(() => {
      return configStore.logoCategoryUid;
    });
    const currentLogo = computed(() => {
      if (logoCategoryUid.value === "MTU3") {
        return require("@/assets/icons/Logo-Era-Tua-Home.svg");
      } else if (logoCategoryUid.value === "MTU4") {
        return require("@/assets/icons/Logo-Era-Tua-Hair.svg");
      } else if (logoCategoryUid.value === "MTYw") {
        return require("@/assets/icons/Logo-Era-Tua-Body.svg");
      } else if (logoCategoryUid.value === "MTc4") {
        return require("@/assets/icons/Logo-Era-Tua-Face.svg");
      } else {
        return require("@/assets/icons/Logo-Era-Tua.svg");
      }

      // home MTU3 // hair MTU4 // body MTYw // face MTc4
    });

    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();

    const isSearchOpen = ref(false);
    let itIsDesktop = ref(false);
    if (app.$device.isDesktop) {
      itIsDesktop = ref(true);
    }
    const productSearchResults = ref<ProductInterface[] | null>(null);

    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(
      () => wishlistStore.wishlist?.items_count ?? 0
    );
    const activeNav = ref(-1);
    const currentCategory = ref<CategoryTree | null>(null);
    const setCurrentCategory = (category: CategoryTree | null) => {
      currentCategory.value = category;
      activeNav.value = -1;
    };
    const hasChildren = (category: CategoryTree) =>
      Boolean(category?.children?.length);

    const onMouseEnter = (category: CategoryTree, index) => {
      if (index === 0) {
        activeNav.value = 0;
      } else {
        activeNav.value = index;
      }
      currentCategory.value = category;
    };

    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);
    const accountIcon = computed(() =>
      isAuthenticated.value ? "profile_fill" : "profile"
    );
    const categoryTree = ref([]);

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: "customer-my-profile" }));
      } else {
        toggleLoginModal();
      }
    };
    const { getStoryblokData } = useStoryblok();
    const storyblokData = ref(null);

    const setNoScrollOnBody = (noScroll, type) => {
      if (noScroll) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
      if (type === "mob-nav") {
        toggleMobileMenu();
      }
      if (type === "cart") {
        toggleCartSidebar();
      }
    };
    const loadCategoryMenu = async () => {
      const categories = useCategoryStore();
      if (categories.categories === null) {
        await categories.load();
      }
      setNoScrollOnBody(true, "mob-nav");
    };
    const closeMenuAndNavigate = () => {
      activeNav.value = -1;
    };

    useFetch(async () => {
      if (!isCategoryTreeLoaded.value) {
        await loadCategoryTree();
        categoryTree.value = categoryTreeWithColors.value?.children.filter(
          (category) => category.include_in_menu
        );
      }

      await getStoryblokData("mainmenu").then((res) => {
        storyblokData.value = res;
      });

      await loadCart();
    });

    onMounted(async () => {
      await loadCartTotalQty();
    });

    return {
      accountIcon,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      categoryTree,
      getCatLink,
      handleAccountClick,
      isAuthenticated,
      isSearchOpen,
      productSearchResults,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      wishlistHasProducts,
      wishlistItemsQty,
      hasCurrencyToSelect,
      hasStoresToSelect,
      setCurrentCategory,
      currentCategory,
      hasChildren,
      onMouseEnter,
      isCartSidebarOpen,
      activeNav,
      storyblokData,
      itIsDesktop,
      setNoScrollOnBody,
      loadCategoryMenu,
      isMobileMenuOpen,
      currentLogo,
      closeMenuAndNavigate,
      logoCategoryUid,
      logoDefault,
    };
  },
});
