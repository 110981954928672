











import { useFetch, defineComponent, ref } from "@nuxtjs/composition-api";
import useStoryblok from "~/composables/useStoryblok";
export default defineComponent({
  name: "TheTopBar",
  setup() {
    const { getStoryblokData } = useStoryblok();
    const storyblokData = ref(null);

    useFetch(async () => {
      await getStoryblokData("topbar").then((res) => {
        storyblokData.value = res;
      });
    });

    return { storyblokData };
  },
});
