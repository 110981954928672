export default `
  query route($url: String!) {
    route(url: $url) {
      type
      ... on CategoryTree {
          uid
      }
      ... on ProductInterface {
          sku
      }
      ... on CmsPage {
        identifier
      }
      ... on CategoryInterface {
        uid
      }
    }
  }
`;
