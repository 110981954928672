//
//
//
//
//
//
//
//
//
//
//
//
//

import SimpleLink from "./SimpleLink.vue";

export default {
  props: ["blok"],
  components: {
    SimpleLink,
  },
};
