import { useContext } from '@nuxtjs/composition-api';
import { useConfigStore } from '~/stores/config';

const useStoryblok = () => {
  const { app } = useContext()
  const configStore = useConfigStore();

  const storeCode = configStore.storeConfig.store_code
  const version = process.env.NODE_ENV === 'development' ? 'draft' : 'published'
  let lang = ''

  if (storeCode === 'fr_et') {
    lang = 'fr'
  } else if (storeCode === 'us_et') {
    lang = 'en'
  } else if (storeCode === 'de_et') {
    lang = 'de'
  }

  const getStoryblokData = async (page) => {
    const { data } = await app.$storyapi.get(`cdn/stories/${page}`, {
      version: version,
      language: lang
    });

    return data
  }

  return {
    getStoryblokData
  }
}

export default useStoryblok