import { Middleware } from '@nuxt/types';
import { usePageStore } from '~/stores/page';
import { Logger } from '~/helpers/logger';
import { RoutableInterface } from '~/modules/GraphQL/types';
import customQuery from './customRouteQuery.gql'

const urlResolverMiddleware : Middleware = async (context) => {
  const pageStore = usePageStore();
  const { path } = context.route;

  const clearUrl = path.replace(/[a-z]+\/[cp|]\//gi, '').replace(`/${context.i18n.locale}`, '');

  Logger.debug('middleware/url-resolver', clearUrl);

  // const { data, errors } = await context.app.$vsf.$magento.api.route(clearUrl);

  const { data, errors } :any = await context.app.$vsf.$magento.api.customQuery({query: customQuery, queryVariables: { url: clearUrl }, customHeaders: {
    store: context.i18n.locale
  }})

  Logger.debug('middleware/url-resolver/result', { data, errors });

  const results: RoutableInterface | null = data?.route ?? null;

  /* if (!results || errors?.length) context.error({ statusCode: 404 }); */

  pageStore.$patch((state) => {
    if (!results || errors?.length) {
      state.routeData = {
        type: 'STORYBLOK',
      };
    } else {
      state.routeData = results;
    }
    
  });
};

export default urlResolverMiddleware;
