



import { defineComponent } from "@nuxtjs/composition-api";

export default defineComponent({
  name: "BackDrop",
  props: {
    bgColor: {
      type: String,
      default: "",
    },
    link: {
      type: [String, Object],
      default: "",
    },
    hasChildren: {
      type: Boolean,
      default: false,
    },
  },
});
